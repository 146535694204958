/**
 * Campaign Partner Pixels Configuration module
 * @module apps/multi-step-app/brands/clc/pixel-config.js
 *
 * Structure:
 *      The pixel config contains pixels data for each specific campaign partner. To add a
 *      new campaign partner pixel to this config, the structure is as follows:
 *
 *          [campaignId]: {
 *              // default settings applied to all lifecycle events for this campaign can be added at this level
 *              lifecycle: {
 *                  onEntry: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              position: "head" | "body",
 *                              code: string | function,
 *                          },
 *                      ],
 *                  },
 *                  onRouteChange: {
 *                      entriesList: [
 *                          action: () => {},
 *                      ],
 *                  },
 *                  postSubmit: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              code: string | function,
 *                              hasDocumentWriteFn: boolean,
 *                              postback: object | boolean,
 *                          },
 *                      ],
 *                  }
 *                  [some_lifecycle_event]: object | boolean,
 *              },
 *          }
 *
 * Property values:
 *      campaignId {number}             - the campaign id associated with the campaign partner.
 *                                        `campaignId: 0` is a global entry, for pixels that should be loaded regardless of
 *                                        campaign (i.e Facebook Pixel, Snapchat Pixel, Twitter Pixel, etc).
 *
 *      lifecycle {object}              - contains the lifecycle events the pixel can have
 *
 *      onEntry {boolean || object}     - tells the CampaignHandler whether or not a pixel needs to be
 *                                        injected at the beginning of the app. If a pixel is NOT required,
 *                                        then a value of `false` will suffice. If a pixel IS required to
 *                                        be injected, then include in the `onEntry` object a `code`
 *                                        property that defines the html pixel to be injected.
 *
 *      onRouteChange {object}          - a lifecycle event for route changes in the app, execute some action (i.e call a function)
 *
 *      postSubmit {boolean || object}  - similar to the onEntry event, this tells the CampaignHandler whether or not to
 *                                        fire some sort of post-submit logic or inject some code into the DOM. The shape
 *                                        and values of this object is also similar to onEntry, where a `code` and `position`
 *                                        property can also be defined.
 *
 *      entriesList {array}        - a list of pixel entries where each entry represents a pixel code and postback if applicable
 *
 *      position {string}               - the position of where the pixel should be injected into the DOM.
 *
 *      code {string || function}       - defines the html pixel code to be injected into the DOM. This property's
 *                                        value can be a template literal (string) for static html snippets or a
 *                                        function for dynamic html snippets.
 *
 *      hasDocumentWriteFn {boolean}    - found/defined within a lifecycle event. This is to guard against unexpected behaviours
 *                                        if 'document.write' is known to be executed. If 'true', 'document.write' is replaced
 *                                        temporarily before being restored again after its invocation.
 *
 *      postback {boolean || object}    - a property to indicate that a request is required to be sent to some partner API for
 *                                        postback purposes, usually to approve/decline transactions. Within this property, there
 *                                        contains an `apiUrl` and `payload` properties that's for the target partner API configuration.
 *
 *      url {string}                    - the postback API url
 *
 *      payload {object || function}    - similar to the `code` property, this defines the payload for the postback request.
 *
 *      httpMethod {string}             - the http method for the postback request
 *
 *      headers {object}                - the headers for the postback request
 *
 *      * In any of the lifecycle events where code is needed to be injected (i.e onEntry, postSubmit), the default
 *        `position` property can be overridden by defining a `position` property within the lifecycle event itself.
 *        If a position is defined within the lifecycle event, that position will be used upon injection. Otherwise,
 *        the default position defined will be used.
 *
 * Method signatures:
 *      code(_, <param_to_use>) => string | payload(_, <param_to_use>) => object
 *          * the stage/event name {string} is passed into first argument
 *          * the campaignData fetched from the app is passed into the second argument of this
 *            method so that any property within campaignData can be used for a dynamic pixel snippet.
 *
 * Caveats:
 *      `code` property:
 *          * CSP (Content Security Policy) - New pixels being added and used in the config need to be verified in a
 *                                            production build run. CSP policies can cause the pixels to fail, thus it
 *                                            is important that the source domains/subdomains of the pixels are being added
 *                                            to the CSP directives in `gatsby-config.js` under the CLC Loan Application
 *                                            (path: /apps/clc-loan-application).
 *
 *          * Code retrieved from the `code` property MUST be valid HTML or the injection will fail.
 *
 *          * The code defined must be simple html. Since the html code is being added into the DOM via `innerHTML`, this
 *            means any embedded tags inside another tag will not be executed. i.e For tags such as <script>, they must be
 *            top-level in order for it to be fired properly.
 */

import { facebook, gtm, snapchat, tiktok, twitter } from "../../src/utils/pixels";

export default {
    0: { // global
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    { // Facebook Pixel
                        position: "body",
                        code: facebook(["649739105625403"]),
                    },
                    { // Snapchat Pixel
                        code: snapchat(["408202b8-8b63-4d7f-8083-e3d0b76acf9d"]),
                    },
                    { // Twitter Pixel
                        code: twitter(["o4cnq"]),
                    },
                    { // TikTok Pixel
                        // CLC (C0EMODTJE9B8D1KPN100)
                        // CA2JQ4RC77UAH7NCF3C0
                        code: tiktok(["C0EMODTJE9B8D1KPN100", "CA2JQ4RC77UAH7NCF3C0"]),
                    },
                    { // Google Pixel
                        code: gtm(["AW-837130465"]),
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    { // Facebook Pixel
                        action: () => {
                            if (window.fbq) window.fbq("track", "PageView");
                        },
                    },
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "PAGE_VIEW");
                        },
                    },
                    { // Twitter Pixel
                        action: () => {
                            if (window.twq) window.twq("track", "PageView");
                        },
                    },
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) window.ttq.track("Browse");
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "SIGN_UP");
                        },
                    },
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) window.ttq.track("Purchase");
                        },
                    },
                    { // Gtag
                        action: () => {
                            if (window.gtag) {
                                window.gtag("event", "conversion", {
                                    "send_to": "AW-837130465/1tijCLD1zNYDEOGxlo8D",
                                    "value": 50.0,
                                    "currency": "CAD",
                                });
                            }
                        },
                    },
                ],
            },
        },
    },

    87: { // O2C
        lifecycle: {
            onEntry: false,
            onRouteChange: false,
            postSubmit: {
                position: "body",
                entriesList: [
                    {
                        code: (_, { s2 = "" } = {}) => `
                            <iframe 
                                src="https://trk.besttrktoday.com/aff_l?offer_id=736&transaction_id=${s2}" 
                                scrolling="no" 
                                frameborder="0" 
                                width="1" 
                                height="1">
                            </iframe>
                        `,
                        postback: false,
                    },
                ],
            },
        },
    },

    90: { // Outbrain
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    {
                        code: `<script data-obct type="text/javascript">
                                    /** DO NOT MODIFY THIS CODE**/
                                    !function(_window, _document) {
                                    var OB_ADV_ID='0067ab0fe634a618f4d88dc1db6ef3c17b';
                                    if (_window.obApi) {var toArray = function(object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));return;}
                                    var api = _window.obApi = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.1';api.loaded = true;api.marketerId = OB_ADV_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = '//amplify.outbrain.com/cp/obtp.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);}(window, document);
                                    obApi('track', 'PAGE_VIEW');
                                </script>
                        `,
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    {
                        action: () => {
                            if (window.obApi) window.obApi("track", "PAGE_VIEW");
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    {
                        code: `<script type="text/javascript">
                                    obApi('track', 'Test');
                                </script>
                        `,
                        postback: false,
                    },
                ],
            },
        },
    },

    91: { // Fintel Connect
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    {
                        code: `<script src="https://app.fintelconnect.com/assets/scripts/fcanalytics.js"></script>`,
                    },
                ],
            },
            onRouteChange: false,
            postSubmit: {
                position: "body",
                entriesList: [
                    {
                        code: (_, { lead_id = "", s4 = "", message = "" } = {}) => message.toLowerCase() === "lead accepted" && `
                            <script>fcpixel.attribution("s4",30,"first","carloanscanadafc","");</script>
                            <script type="text/javascript">
                                var pid = "car_loan";
                                fcpixel.pxl(24661,"${lead_id}",pid,"${lead_id}",0,0,"${s4}","carloanscanadafc");
                            </script>
                        `,
                        hasDocumentWriteFn: true,
                        postback: false,
                    },
                ],
            },
        },
    },
};