import React, { useMemo } from "react";
import { useApplicationContext } from "@clcdev/gatsby-theme-application/state";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Img from "gatsby-image";
import { useImageData } from "@hooks/use-image-data";
import DisclaimerText from "./DisclaimerText";

// makeStyles (hook API)
const useContainerStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: "60px",

        [theme.breakpoints.down(400)]: {
            padding: 0,
        },
        [theme.breakpoints.between(400, 650)]: {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
    },
}));
// withStyles (HOC)
const LogosContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-around",
        color: "white",
        padding: "40px 0px",

        [theme.breakpoints.down(400)]: {
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        [theme.breakpoints.between(400, 650)]: {
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        [theme.breakpoints.down(750)]: {
            justifyContent: "space-evenly",
        },
    },
}))(Container);

const ImageBox = withStyles((theme) => ({
    root: {
        width: "200px",

        [theme.breakpoints.down(675)]: {
            width: "175px",
        },
    },
}))(Box);

const TitleContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "65px 0px 60px 0px",

        [theme.breakpoints.down(400)]: {
            display: "none",
        },
    },
}))(Container);

const InputsBox = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "17px",
        padding: "10px 0px",

        [theme.breakpoints.down(400)]: {
            borderRadius: "0px",
        },
    },
}))(Box);

const DisclaimerContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        textAlign: "center",
        padding: "25px 55px",
        marginBottom: "20px",
        borderBottom: "1px solid black",
        lineHeight: "1.45",
        fontSize: "12px",
    },
}))(Container);

const Layout = (props) => {
    const {
        children,
        pageContext,
    } = props;

    const { step } = pageContext;
    const {
        recaptcha: {
            RecaptchaWidget,
        },
    } = useApplicationContext();

    const containerStyles = useContainerStyles();
    const imageData = useImageData();

    const renderTitles = useMemo(() => {
        const commonTitleStyles = {
            fontWeight: 800,
        };
        const titlePropsList = [
            {
                variant: "h6",
                children: "*Hurry, Limited Units Available*",
                style: {
                    color: "#FF3F3F",
                    fontSize: "16px",
                    ...commonTitleStyles,
                },
            },
            {
                variant: "h3",
                children: "59 second App and Drive Away",
                style: {
                    color: "003333",
                    ...commonTitleStyles,
                },
            },
            {
                variant: "h6",
                children: "*FINANCE ONLY*",
                style: {
                    color: "#003333",
                    fontSize: "16px",
                    ...commonTitleStyles,
                },
            },
        ];
        return titlePropsList.map((props, i) => {
            const totalTitles = titlePropsList.length - 1;
            return (
                <React.Fragment key={i}>
                    <Typography {...props}/>
                    {i !== totalTitles && <Box mt={3}/>}
                </React.Fragment>
            );
        });
    }, []);

    const renderLogos = useMemo(() => {
        const imageList = [
            {
                name: "logo",
                loading: "lazy",
                alt: "brand logo",
            },
        ];
        return imageList.map((image, i) => {
            const { name, ...props } = image;
            const hasImage = name in imageData;
            if (!hasImage) {
                console.error(`Image name "${name}" is not found in imageData.`);
            }
            return (
                <ImageBox key={i}>
                    {hasImage &&
                        <Img
                            fluid={imageData[name].childImageSharp.fluid}
                            {...props}
                        />
                    }
                </ImageBox>
            );
        });
    }, [imageData]);

    return (
        <Box
            style={{
                backgroundColor: "#E8F9F3",
                backgroundImage: "",
                height: step === "success" ? "100vh" : "auto",
            }}
        >
            <Container maxWidth="md" classes={containerStyles}>
                <Box style={{ backgroundColor: "#E8F9F3" }}>
                    <LogosContainer maxWidth="sm">
                        {renderLogos}
                    </LogosContainer>
                </Box>
                <TitleContainer>
                    {renderTitles}
                </TitleContainer>
                <InputsBox>
                    <DisclaimerContainer maxWidth="sm">
                        <DisclaimerText />
                    </DisclaimerContainer>
                    <Box>
                        {children}
                    </Box>
                    <Box
                        mb={4}
                        style={{
                            display: step !== "success" ? "flex" : "none",
                            justifyContent: "center",
                        }}
                    >
                        <RecaptchaWidget />
                    </Box>
                </InputsBox>
            </Container>
        </Box>
    );
};

export default Layout;